import { useState, useRef } from 'react';

/**
 * Usage:
 * const [load, isLoading, isLoadingRef] = useIsLoading(async () => {
 *     // async actions
 * });
 *
 * or:
 *
 * const [load, isLoading, isLoadingRef] = useIsLoading(() => {
 *     return {
 *         loading: <value of isLoading while awaiting callback>,
 *         callback: async () => {
 *             // async actions
 *         }
 *     }
 * });
 */
const useIsLoading = callback => {
  const {
    0: loading,
    1: setLoading
  } = useState();
  const isLoadingRef = useRef(loading);
  const wrapperPromise = async function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (!isLoadingRef.current) {
      isLoadingRef.current = true;
      const cb = callback(...args);
      const {
        promise,
        loadingValue,
        finishValue
      } = (() => {
        if (cb.then) {
          return {
            promise: cb,
            loadingValue: true,
            finishValue: false
          };
        }
        const {
          callback: indirectCallback,
          loading: value
        } = cb;
        return {
          promise: indirectCallback(...args),
          loadingValue: value,
          finishValue: undefined
        };
      })();
      setLoading(loadingValue);
      return promise.finally(() => {
        setLoading(finishValue);
        isLoadingRef.current = false;
      });
    }
    return undefined;
  };
  return [wrapperPromise, loading, isLoadingRef];
};
export default useIsLoading;